<template>
  <td class="level" :class="{ expanded }">
    <div class="number" :data-tippy-content="tips[value]">
      {{ value || null }}
    </div>
    <div v-if="expanded" class="rating">
      <li class="unset" @click="input(0)" :data-tippy-content="tips[0]">
        &times;
      </li>
      <li
        v-for="i in 5"
        :key="i"
        class="star"
        :class="{ active: i <= value }"
        @click="input(i)"
        :data-tippy-content="tips[i]"
      >
        <IconStar />
      </li>
    </div>
  </td>
</template>

<script>
import IconStar from "@/icons/star-solid.svg";

export default {
  name: "SkillsLevel",
  props: {
    value: {
      default: 0,
    },
    expanded: {
      default: false,
    },
  },
  components: {
    IconStar,
  },
  data() {
    return {
      tips: [
        "Ne sait pas faire du tout",
        "A besoin d'aide",
        "Peut faire mais lentement",
        "Sait faire tout seul",
        "Enseigne à quelqu'un",
        "Référent sur le sujet",
      ],
    };
  },
  methods: {
    input(level) {
      this.$emit("input", level);
    },
  },
};
</script>
