import { mapGetters } from 'vuex';
import { delegate as tippyDelegate } from "tippy.js";
import Draggable from "vuedraggable";
import Multiselect from 'vue-multiselect';
import ResourceSelect from "@/views/common/ResourceSelect.vue";
import GroupSelect from "@/views/common/GroupSelect.vue";
import SkillsLevel from "./SkillsLevel.vue";
import IconRemove from "@/icons/trash-solid.svg";
import IconEdit from "@/icons/pen-solid.svg";
import IconRight from "@/icons/arrow-right-solid.svg";
import IconLeft from "@/icons/arrow-left-solid.svg";
import IconHandle from "@/icons/grip-lines-solid.svg";
import IconAdd from "@/icons/plus-square-solid.svg";
import { SORTED_CATEGORIES_KEY } from '@/config/constants';
import { userIsAtLeastDP } from '@/app/helpers';


export default {
    name: "Skills",
    components: {
        Draggable,
        Multiselect,
        ResourceSelect,
        GroupSelect,
        SkillsLevel,
        IconRemove,
        IconEdit,
        IconRight,
        IconLeft,
        IconHandle,
        IconAdd,
    },
    data() {
        return {
            group: null,
            user: null,
            skillEditing: null,
            categories: [],
            sortedCategories: JSON.parse(localStorage.getItem(SORTED_CATEGORIES_KEY)) || [],
            loadedUsersSkills: [],
            usersSkills: {
                usersSkills: [],
                skills: [],
            },
            rating: null,
            renamingCategory: {
                from: null,
                to: null,
            },
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            userLogged: 'Resource/auth/user',
            users: 'Resource/list/resources',
            groups: 'Group/edit/groups',
            skills: 'Skill/skills',
        }),
        selectedUsers() {
            if (!this.user && !this.group) return [];
            if (this.user) return [this.user];
            const userIds = this.group.userIds.map(id => parseInt(id))
            return this.users.filter(user => userIds.includes(user.id));
        },
        multipleUsers() {
            return this.selectedUsers.length > 1;
        },
        indexedSkills() {
            return this.skills.reduce((indexedSkills, skill) => {
                (indexedSkills[skill.category] = indexedSkills[skill.category] || []).push(skill);
                return indexedSkills;
            }, {});
        },
        canSeePrivateSkills() {
            return userIsAtLeastDP(this.userLogged);
        },
    },
    methods: {
        getSkills() {
            this.$store.dispatch('Skill/getList').then(() => {
                this.categories = Object.keys(this.indexedSkills).sort((a, b) => this.sortedCategories.indexOf(a) - this.sortedCategories.indexOf(b));
            });
        },
        addCategory(category) {
            this.categories.unshift(category);
            this.skillEditing.category = category;
        },
        submitSkill() {
            if (!this.skillEditing.name || !this.skillEditing.category) return;
            this.$store.dispatch('Skill/upsert', this.skillEditing).then(() => {
                this.skillEditing = null;
                this.getSkills();
                this.getUsersSkills();
            });
        },
        removeSkill(skill) {
            if (!window.confirm('Are you sure you want to delete this skill ?\nRelated assigned skill levels will also be deleted')) return;
            this.$store.dispatch('Skill/remove', skill.id).then(() => {
                this.$store.dispatch('Skill/getList');
                this.getUsersSkills();
            });
        },
        openSkill(skill) {
            this.skillEditing = skill;
            const vm = this;
            this.$nextTick(() => {
                vm.$refs.skillName.focus();
            });
        },
        openUserSkill(user, skill) {
            this.rating = { user, skill };
        },
        closeUserSkill() {
            this.rating = null;
        },
        saveCategories() {
            this.sortedCategories = this.categories;
            localStorage.setItem(SORTED_CATEGORIES_KEY, JSON.stringify(this.categories));
        },
        moveCategory() {
            this.saveCategories();
            this.getUsersSkills();
        },
        openRenameCategory(from) {
            this.renamingCategory.from = this.renamingCategory.to = from;
            this.$nextTick(() => {
                const $el = this.$refs['cat' + from][0]
                $el.focus();
                $el.select();
            });
        },
        closeRenameCategory() {
            this.renamingCategory.from = null;
            this.renamingCategory.to = null;
        },
        renameCategory() {
            const cat = this.renamingCategory;
            if (cat.from === cat.to) return this.closeRenameCategory();
            if (!cat.from || !cat.to) return this.closeRenameCategory();
            this.$store.dispatch('Skill/renameCategory', cat).then(() => {
                this.categories[this.categories.indexOf(cat.from)] = cat.to;
                this.closeRenameCategory();
                this.saveCategories();
                this.getSkills();
                this.getUsersSkills();
            });
        },

        isRating(user, skill) {
            return this.rating && this.rating.user === user && this.rating.skill === skill;
        },
        addSkillToUsers(skill) {
            if (this.usersSkills.skills.indexOf(skill) !== -1) return;
            if (!this.selectedUsers.length) return;
            for (let i in this.selectedUsers) {
                const userSkills = this.usersSkills.usersSkills[this.selectedUsers[i].id];
                userSkills[skill.id] = userSkills[skill.id] || 0;
            }
            this.usersSkills.skills.push(skill);
        },
        getUsersSkills() {
            this.loadedUsersSkills = [];
            this.usersSkills.skills = [];
            this.usersSkills.usersSkills = [];
            if (!this.selectedUsers.length) return;
            this.$store.dispatch('Skill/getUsersSkills', this.selectedUsers.map(user => user.id)).then((data) => {
                this.loadedUsersSkills = data.map(userSkill => ({
                    id: userSkill.id,
                    user: this.selectedUsers.find(user => userSkill.userId == user.id),
                    skill: this.skills.find(skill => userSkill.skillId == skill.id),
                    level: userSkill.level,
                }));
                this.loadedUsersSkills.sort((a, b) => this.categories.indexOf(a.skill.category) - this.categories.indexOf(b.skill.category));
                for (let i in this.selectedUsers) {
                    this.usersSkills.usersSkills[this.selectedUsers[i].id] = [];
                }
                for (let i in this.loadedUsersSkills) {
                    const userSkill = this.loadedUsersSkills[i];
                    this.addSkillToUsers(this.skills.find(skill => skill === userSkill.skill));
                    this.usersSkills.usersSkills[userSkill.user.id][userSkill.skill.id] = userSkill.level;
                }
            });
        },
        setUserSkill(user, skill, level) {
            // console.log('Setting level', level, 'for user', user.id, 'skill', skill.id);
            const loadedUserSkill = this.loadedUsersSkills.find(userSkill => userSkill.user === user && userSkill.skill === skill) || {}
            const payload = {
                id: loadedUserSkill.id,
                userId: user.id,
                skill: skill.id,
                level,
            };
            const promise = (!level)
                ? this.$store.dispatch('Skill/removeUserSkill', payload.id)
                : this.$store.dispatch('Skill/upsertUserSkill', payload);
            promise.then(() => {
                this.rating = null;
            });
        },
    },
    watch: {
        user(value) {
            if (value) this.group = null;
            this.getUsersSkills();
        },
        group(value) {
            if (value) this.user = null;
            this.getUsersSkills();
        },
    },
    mounted() {
        this.$store.dispatch('Group/edit/getList');
        this.getSkills();
        tippyDelegate(this.$refs.skills, {
            target: '[data-tippy-content]',
        });
        // tippyDelegate(this.$refs.usersSkills, {
        //     target: '[data-tippy-content]',
        // });
    }
}
